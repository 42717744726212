import React from 'react'
import styled from 'styled-components'

function Wrapper({ children, className, ...props }) {
  return (
    <TextContainer className={className} {...props}>
      {children}
    </TextContainer>
  )
}

// filter out `Line` props
const Text = ({
  color,
  fontSize,
  lineHeight,
  className,
  children,
  ...props
}) => (
  <span className={className} {...props}>
    {children}
  </span>
)

const TextContainer = styled('h1')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  font-size: 6vmax;
  font-weight: 800;
  text-transform: uppercase;
  line-height: ${props => props.lineHeight || '1'};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const Line = styled(Text)`
  display: block;
  color: ${props => props.color || '#ffffff'};
  font-size: ${props => props.fontSize || '1em'};
  line-height: ${props => props.lineHeight || 'inherit'};
  @media (min-width: 769px) {
    max-width: 80vw;
  }
`

export default {
  Wrapper,
  Line
}
