import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'

const Person = ({ name, title, image, className, ...props }) => (
  <StyledFigure className={className} {...props}>
    <StyledImage fixed={image} alt="" />
    <StyledCaption>
      <Name>{name}</Name>
      <Title>{title}</Title>
    </StyledCaption>
  </StyledFigure>
)

const StyledFigure = styled('figure')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  margin-bottom: 0;
  & > * {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
  width: 200px;
`

const StyledImage = styled(Image)`
  @media (max-width: 768px) {
    width: 120px !important;
    height: 120px !important;
    border-radius: 60px;
  }
  @media (min-width: 769px) {
    width: 200px !important;
    height: 200px !important;
    border-radius: 100px;
  }
`

const StyledCaption = styled('figcaption')`
  text-align: center;
  font-size: 18px;
`

const Name = styled('span')`
  display: block;
  color: #ffffff;
  margin-bottom: 1rem;
`

const Title = styled('span')`
  display: block;
  color: #aaaaaa;
`

export default Person
