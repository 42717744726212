import React from 'react'

import FullWidthWrapper from './FullWidthWrapper'
import ForegroundLayout from './ForegroundLayout'

function ContentBlock({
  height,
  minHeight,
  backgroundColor,
  children,
  ...props
}) {
  return (
    <FullWidthWrapper
      height={height}
      minHeight={minHeight}
      {...props}
      css={`
        z-index: 1;
        background-color: ${backgroundColor || 'unset'};
        // &::before {
        //   content: '';
        //   width: 100%;
        //   height: 120%;
        //   position: absolute;
        //   top: -10%;
        //   left: 0;
        //   transform: skewY(-5.7deg);
        //   background-color: ${backgroundColor || 'unset'};
        // }
      `}
    >
      <ForegroundLayout
        css={`
          padding: 5vh 0;
        `}
      >
        {children}
      </ForegroundLayout>
    </FullWidthWrapper>
  )
}

export default ContentBlock
