import React from 'react'
import styled from 'styled-components'

const FullWidthWrapper = ({ height, minHeight, children, ...props }) => (
  <StyledSection height={height} minHeight={minHeight} {...props}>
    <OverflowWrapper>{children}</OverflowWrapper>
  </StyledSection>
)

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  min-height: ${props => props.minHeight || 'initial'};
  height: ${props => props.height || 'initial'};
`

const OverflowWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export default FullWidthWrapper
