import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Select from 'react-select'
import qs from 'qs'

import FlexBox from './FlexBox'
import Person from './Person'
import ArrowWhite from '../graphics/ne-arrow-white.svg'

const contactReasons = [
  { value: 'Brand Book Publishing', label: 'Brand Book Publishing' },
  { value: 'Digital Marketing', label: 'Digital Marketing' },
  { value: 'Video Production', label: 'Video Production' },
  {
    value: 'Website Design & Development',
    label: 'Website Design & Development'
  },
  { value: 'App Design & Development', label: 'App Design & Development' },
  { value: 'Visual Marketing', label: 'Visual Marketing' },
  { value: 'Beacon Advertising', label: 'Beacon Advertising' }
]

const status = {
  idle: 'idle',
  invalid: 'invalid',
  processing: 'processing',
  success: 'success',
  failure: 'failure'
}

const indicator = {
  idle: {
    message: '',
    color: ''
  },
  invalid: {
    message: 'Make sure you include your name, email, and a message!',
    color: '#f58934'
  },
  processing: {
    message: 'Sending...',
    color: '#888888'
  },
  success: {
    message: "Transmission received. We'll get back to you shortly.",
    color: '#69b939'
  },
  failure: {
    message: 'Transmission failure.',
    color: '#ff5522'
  }
}

// function hubspotPayloadForData(data) {
//   return {
//     fields: [
//       { name: 'firstname', value: data.firstName },
//       { name: 'lastname', value: data.lastName },
//       { name: 'email', value: data.email },
//       { name: 'message', value: data.message },
//       { name: 'company', value: data.company }
//     ]
//   }
// }

const ContactForm = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    services: [],
    message: ''
  }
  const [data, setData] = useState(initialState)
  const [submitStatus, setSubmitStatus] = useState(status.idle)
  function onSubmit() {
    if (typeof window !== 'undefined') {
      if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
        // eslint-disable-next-line no-undef
        fbq('track', 'Contact')
        // eslint-disable-next-line no-undef
        gtag('event', 'generate_lead', { event_label: 'Contact' })
      }
      // const hubspotURL =
      //   'https://api.hsforms.com/submissions/v3/integration/submit/5857151/df049764-a67f-4d74-80a0-9c8338f5fa9a'
      // const hubspotConfig = {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(hubspotPayloadForData(data))
      // }
      // window
      //   .fetch(hubspotURL, hubspotConfig)
      //   .then(response => response.json())
      //   .then(json => console.log(json))
      //   .catch(error =>
      //     console.log('Failed to send form data to Hubspot:', error)
      //   )
      const url =
        'https://script.google.com/macros/s/AKfycbx7xGIr_U2Wg5xLhVnpbpNlK-j9zoRQcUVQnCuCfGH_0mIcmw8/exec'
      const config = {
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        referrer: 'no-referrer',
        body: qs.stringify(data, { arrayFormat: 'comma' }) // best format for google scripts
      }
      return window.fetch(url, config).then(response => response.json())
    }
  }

  return (
    <FlexBox
      as="form"
      id="contact-form"
      flexDirection="column"
      alignItems="center"
      onSubmit={event => {
        event.preventDefault()
        if (data.email !== '' && data.message !== '') {
          setSubmitStatus(status.processing)
          onSubmit().then(response => {
            if (response.result === 'success') {
              setSubmitStatus(status.success)
            } else {
              setSubmitStatus(status.failure)
            }
          })
        } else {
          setSubmitStatus(status.invalid)
        }
      }}
    >
      <FlexBox
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        itemSpacing="5vw"
        isResponsive
      >
        <ContactPerson />
        <FlexBox
          flexDirection="column"
          itemSpacing="1rem"
          css={`
            flex: auto;
            min-width: 300px;
            max-width: 500px;
          `}
        >
          <TextInput
            type="text"
            name="firstname"
            aria-label="firstname"
            placeholder="First Name"
            value={data.firstName}
            onChange={event =>
              setData({ ...data, firstName: event.target.value })
            }
          />
          <TextInput
            type="text"
            name="lastname"
            aria-label="lastname"
            placeholder="Last Name"
            value={data.lastName}
            onChange={event =>
              setData({ ...data, lastName: event.target.value })
            }
          />
          <TextInput
            type="email"
            name="email"
            aria-label="email"
            placeholder="Email"
            value={data.email}
            onChange={event => setData({ ...data, email: event.target.value })}
          />
          <TextInput
            type="text"
            name="company"
            aria-label="company"
            placeholder="Company"
            value={data.company}
            onChange={event =>
              setData({ ...data, company: event.target.value })
            }
          />
          <Select
            name="services"
            aria-label="services"
            isMulti
            placeholder="How can we help?"
            options={contactReasons}
            styles={selectStyles}
            onChange={selectData =>
              setData({ ...data, services: selectData.map(obj => obj.label) })
            }
          />
          <TextArea
            name="message"
            aria-label="message"
            placeholder="Tell us more..."
            value={data.message}
            onChange={event =>
              setData({ ...data, message: event.target.value })
            }
          />
        </FlexBox>
        <SubmitButton
          disabled={submitStatus === status.processing}
          statusMessage={indicator[submitStatus].message}
          statusColor={indicator[submitStatus].color}
        >
          <ArrowWhite />
        </SubmitButton>
      </FlexBox>
    </FlexBox>
  )
}

const ContactPerson = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "about-frank.jpg" }) {
          childImageSharp {
            fixed(height: 200, quality: 100, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Person
        name={'Frank Rocks'}
        title={'Account Executive'}
        image={data.image.childImageSharp.fixed}
        css={`
          @media (max-width: 768px) {
            display: none;
          }
        `}
      />
    )}
  />
)

const TextInput = styled.input`
  font-size: 18px;
  font-weight: 400;
  padding: 0 25px;
  height: 50px;
  border: 0;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  transition: background-color 100ms linear;
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::placeholder {
    color: #a1a2a3;
    opacity: 1;
  }
`

const TextArea = styled.textarea`
  font-size: 18px;
  font-weight: 400;
  padding: 25px;
  min-height: 150px;
  border: 0;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  transition: background-color 100ms linear;
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::placeholder {
    color: #a1a2a3;
    opacity: 1;
  }
`

const SubmitButton = styled.button`
  position: relative;
  cursor: pointer;
  flex: none;
  padding: 0 15px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  border: 0;
  background-color: #f93616;
  transition: transform 200ms ease-out;
  &:hover {
    transform: scale(1.1);
  }
  &::after {
    content: "${props => props.statusMessage || ''}";
    position: absolute;
    top: 100px;
    left: 0;
    width: 150px;
    padding: 0.5rem;
    color: #ffffff;
    background-color: ${props => props.statusColor || 'transparent'};
  }
`

const selectStyles = {
  control: (initial, state) => ({
    ...initial,
    minHeight: '50px',
    borderRadius: '25px',
    border: '0',
    backgroundColor: state.isFocused
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(255, 255, 255, 0.05)',
    fontSize: '18px',
    fontWeight: '400',
    boxShadow: 'none',
    transition: 'background-color 100ms linear',
    padding: '0 15px'
  }),
  input: (initial, state) => ({
    ...initial,
    color: '#ffffff'
  }),
  placeholder: (initial, state) => ({
    color: '#a1a2a3',
    opacity: '1'
  }),
  indicatorSeparator: (initial, state) => ({
    ...initial,
    display: 'none'
  }),
  option: (initial, state) => ({
    ...initial,
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: '#f93616',
      color: '#ffffff'
    }
  }),
  multiValue: (initial, state) => ({
    ...initial,
    backgroundColor: '#f93616',
    color: '#ffffff'
  }),
  multiValueLabel: (initial, state) => ({
    ...initial,
    color: '#ffffff'
  })
}

export default ContactForm
