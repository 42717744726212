import React from 'react'

const AngledDivider = ({ color, position, ...props }) => (
  <React.Fragment>
    {position === 'top' && (
      <div
        css={`
          width: 100%;
          position: absolute;
          z-index: 1;
          transform: translateY(-99%);
        `}
        {...props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path fill={color} d="M0,100h1000v-100L0,100z" />
        </svg>
      </div>
    )}
    {position === 'bottom' && (
      <div
        css={`
          width: 100%;
          position: absolute;
          z-index: 1;
          transform: translateY(-1px);
        `}
        {...props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path fill={color} d="M0,100v-100h1000L0,100z" />
        </svg>
      </div>
    )}
  </React.Fragment>
)

export default AngledDivider
