import React from 'react'

import AngledDivider from './AngledDivider'
import FullWidthWrapper from './FullWidthWrapper'
import ForegroundLayout from './ForegroundLayout'
import BigHeading from './BigHeading'
import ContactForm from './ContactForm'

const ContactSection = ({ heading, ...props }) => (
  <React.Fragment>
    <FullWidthWrapper
      minHeight="50vh"
      css={`
        background-color: #2c2c2c;
        margin-bottom: 20vh;
      `}
      {...props}
    >
      <ForegroundLayout
        css={`
          padding: 20vh 0 20vh;
        `}
      >
        <BigHeading
          css={`
            text-align: center;
            margin-bottom: 6vw;
          `}
        >
          {heading || 'Contact Mission Control'}
        </BigHeading>
        <ContactForm />
      </ForegroundLayout>
      <AngledDivider
        color={'#2c2c2c'}
        position={'bottom'}
        css={`
          position: absolute;
          width: 100%;
          z-index: 1;
        `}
      />
    </FullWidthWrapper>
  </React.Fragment>
)

export default ContactSection
