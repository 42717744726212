import React from 'react'
import styled from 'styled-components'

import useScrollOffset from '../hooks/useScrollOffset'

function ParallaxWrapper({ children, ...props }) {
  const offset = useScrollOffset()
  var normalizedOffset = 0
  var opacity = 1
  if (typeof window !== 'undefined') {
    normalizedOffset = offset.y / ((window.innerHeight * 3) / 4)
    opacity = normalizedOffset > 1 ? 0 : 1 - normalizedOffset
  }
  return (
    <Wrapper contentOpacity={opacity} {...props}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled('div').attrs(props => {
  return {
    style: {
      ...props.style,
      opacity: props.contentOpacity
    }
  }
})`
  position: fixed;
  width: 100%;
  @media (min-width: 769px) {
    position: fixed;
  }
`

export default ParallaxWrapper
